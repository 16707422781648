import { Slider, Slide } from 'components/Slider/Slider';
import HeroNewsCard from 'pages/Homepage/Hero/HeroNewsCard';
import 'swiper/css';
import './styles.scss';
import GradientText from 'components/GradientText';
import { useSelector } from 'react-redux';
import { selectHomepage } from 'store/slices/HomepageSlice';
import { selectLatestPostHomepage } from 'store/slices/BlogSlice';
import { useEffect, useRef, useState } from 'react';
import Spline from '@splinetool/react-spline';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SolutionCard } from 'components/SolutionCard/SolutionCard';
import video from 'images/new-video.mp4';
import { test } from './helpers';

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
    const { first_screen_title, banner_title, banner_cards = [] } = useSelector(selectHomepage);
    const posts = useSelector(selectLatestPostHomepage);
    const videoEl = useRef(null);
    const [loaded, setLoaded] = useState(false);

    const attemptPlay = () => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch(error => {
                console.error('Error attempting to play', error);
            });
    };

    useEffect(() => {
        attemptPlay();
    }, []);

    const onLoad = spline => {
        const obj = spline.findObjectById('8ee353d4-07de-46c1-86d1-53b653da295e');
        if (!!obj) {
            setLoaded(true);
        }
    };

    const container = useRef();

    useGSAP(
        () => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.hero',
                    start: 'top top',
                    end: '+=500px',
                    scrub: 1,
                    // markers: true,
                },
            });
            tl.fromTo(
                '.information-wrapper__news-wrapper',
                { marginRight: -8, opacity: 1 },
                {
                    marginRight: -366 - 16,
                    opacity: 0,
                },
            );
            tl.to('.information-wrapper__box-for-border', { translateX: 366, opacity: 0 }, '<');
            tl.to('.hero__piece', { translateX: 358 }, '<');
            tl.to('.information-wrapper__offer-section', { height: '780px' }, '<');
            tl.fromTo(
                'canvas',
                { translateX: '35%', translateY: '-32%', scale: 0.6 },
                {
                    translateX: '0%',
                    translateY: '0',
                    scale: 1,
                },
                '<',
            );
            tl.to('.information-wrapper__offer-section .gradient-text', { scale: 1.5 }, '<');
            tl.to('.test-helper', { height: '0' }, '<');

            let tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: '.test',
                    start: 'top 90px',
                    end: 'bottom',
                    scrub: 1,
                    pin: true,
                },
            });
            tl2.to('.solution-card--1', { yPercent: -180 }, '<');
            tl2.to('.solution-card--4', { yPercent: -170 }, '-=0.4');
            tl2.to('.solution-card--2', { yPercent: -170 }, '-=0.4');
            tl2.to('.solution-card--3', { yPercent: -130 }, '-=0.3');
        },
        { scope: container, dependencies: [loaded] },
    );

    return (
        <section className="container" ref={container} style={{ overflow: 'hidden' }}>
            <div className="hero">
                <video playsInline loop muted src={video} ref={videoEl} poster={test} />
                <GradientText textColor="white" className="has-animation">
                    <h1 dangerouslySetInnerHTML={{ __html: first_screen_title }} />
                </GradientText>
                <div className="hero__piece" />
            </div>
            <div className="test">
                <div className="information-wrapper">
                    <div className="information-wrapper__offer-section">
                        <div className="asdasdasdasd">
                            <GradientText size="super-small" textColor="white" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: banner_title }} />
                            </GradientText>
                            {/* <Button type="white-arrow" onClick={handleClick} /> */}
                        </div>
                        <Spline
                            className={`canvas-container ${loaded ? 'loaded' : ''}`}
                            scene="https://prod.spline.design/QMHsLmTlrQlLNtn5/scene.splinecode"
                            onLoad={onLoad}
                        />
                    </div>
                    <div className="information-wrapper__box-for-border"></div>
                    <div className="information-wrapper__box-for-border radius16"></div>
                    {/* <div className="information-wrapper__box-for-border location-left"></div>
                    <div className="information-wrapper__box-for-border location-left radius16"></div> */}
                    <div className="information-wrapper__news-wrapper">
                        <Slider className="slider-head">
                            {posts?.map(post => (
                                <Slide key={post.slug}>
                                    <HeroNewsCard card={post} array={posts} />
                                </Slide>
                            ))}
                        </Slider>
                    </div>
                    {banner_cards.map((item, index) => (
                        <SolutionCard
                            key={item.title}
                            index={index}
                            image={item.icon}
                            numbers={item.title}
                            description={item.text}
                        />
                    ))}
                </div>
            </div>
            <div className="test-helper"></div>
        </section>
    );
};

export default Hero;
