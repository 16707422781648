import Hero from './Hero';
import TrialResultsComponent from './TrialResultsComponent';
import MissionAndValuesSection from 'components/MissionAndValuesSection';
import LatestNews from './LatestNews';
import Solutions from './Solutions';
import AboutUs from './AboutUs';
import Products from './Products';
import Instagram from './Instagram';
import DefaultForm from 'components/FormSection/Forms/DefaultForm';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectHomepage, selectHomepageIsLoading } from 'store/slices/HomepageSlice';
import { useAnimation, useAnimationWithStagger } from 'hooks/useAnimation';
import useWindowSize from 'hooks/useWindowSize';
import HeroMobile from './HeroMobile';
import NewInstagram from './NewInstagram';

export const Homepage = () => {
    const loading = useSelector(selectHomepageIsLoading);
    const { mission_background_image, mission_cards, mission_title } = useSelector(selectHomepage);
    const { width } = useWindowSize();

    const container = useRef();

    useAnimation({
        items: [],
        container,
        dependencies: [loading],
    });

    useAnimationWithStagger({
        item: '.discover-item',
        trigger: '.discover',
        container,
        dependencies: [loading],
    });

    useAnimationWithStagger({
        item: '.product-link',
        trigger: '.homepage-product-items',
        container,
        dependencies: [loading],
    });

    return (
        <div ref={container}>
            {width >= 1024 ? (
                <Hero />
            ) : (
                <>
                    <HeroMobile />
                    <Solutions />
                </>
            )}
            <AboutUs />
            <MissionAndValuesSection
                loading={loading}
                background={mission_background_image}
                title={mission_title}
                data={mission_cards}
            />
            <Products />
            <TrialResultsComponent />
            <LatestNews />
            {width >= 1024 ? <NewInstagram loading={loading} /> : <Instagram />}
            <DefaultForm />
        </div>
    );
};
