import { Button } from 'components/buttons/Button/Button';
import NewDropdown from 'components/Dropdown';
import HeaderButton from '../HeaderButton';
import { useDispatch, useSelector } from 'react-redux';
import { changeCountry, changeLanguage, selectLanguageState } from 'store/slices/languageSlice';
import React from 'react';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useNavigate } from 'react-router-dom';

const CountrySelectorMenu = ({ onClose }) => {
    const dispatch = useDispatch();
    const { country, language, languages, countries } = useSelector(selectLanguageState);
    const { menu_change_country_text, menu_choose_country_text, menu_available_languages_text, menu_save_choice_text } =
        useSelector(selectTranslations);

    const onChangeLanguage = language => setState({ ...state, language });
    const onChangeCountry = country => setState({ ...state, country });

    const [state, setState] = React.useState({ language, country });
    const navigate = useNavigate();

    const handleOnSave = () => {
        dispatch(changeLanguage(state.language));
        dispatch(changeCountry(state.country));
        onClose();
        navigate(0);
    };

    return (
        <div className="country-selector-popup">
            <div className="country-selector-popup__title">{menu_change_country_text}</div>
            <HeaderButton onClick={onClose} size="small" />
            <div className="country-selector-controls">
                <NewDropdown
                    label={menu_choose_country_text}
                    selectedValue={state.country}
                    options={countries}
                    onChange={onChangeCountry}
                />
                <NewDropdown
                    label={menu_available_languages_text}
                    selectedValue={state.language}
                    options={languages}
                    onChange={onChangeLanguage}
                />
                <Button onClick={handleOnSave} type="default">
                    {menu_save_choice_text}
                </Button>
            </div>
        </div>
    );
};

export default CountrySelectorMenu;
