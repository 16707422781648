import './styles.scss';
import mask from 'images/X mask.svg';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';

gsap.registerPlugin(ScrollTrigger);

const markers = false;
const ease = 'power1.in';

const MissionAndValuesSection = ({ background = '', title = '', data = [], loading }) => {
    const maskStyle = { maskImage: `url('${mask}')`, WebkitMaskImage: `url('${mask}')` };
    const container = useRef();

    useGSAP(
        () => {
            gsap.from('.mission-section__title div', {
                y: 10,
                opacity: 0,
                ease: ease,
                duration: 0.4,
                scrollTrigger: {
                    trigger: '.mission-section',
                    start: 'top 70%',
                    markers: markers,
                },
            });
            gsap.from('.mission-section__mask', {
                y: 10,
                opacity: 0,
                ease: ease,
                duration: 0.4,
                scrollTrigger: {
                    trigger: '.mission-section',
                    start: 'top 50%',
                    markers: markers,
                },
            });
        },
        { dependencies: [loading, data], scope: container },
    );

    useGSAP(
        () => {
            if (data.length) {
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.mission-section',
                        start: 'top 8px',
                        end: '+=100%',
                        scrub: 1,
                        markers: markers,
                        pin: true,
                    },
                });
                tl.fromTo('.mission-section__mask', { maskSize: 40 }, { maskSize: 500 })
                    .to('.mission-section__title', { opacity: 0 }, '<')
                    .fromTo('.values-card-wrapper', { yPercent: 400 }, { yPercent: 0, stagger: 0.1 }, '-=.2')
                    .fromTo('.values-section', { yPercent: 0 }, { yPercent: -50 });
            }
        },
        { dependencies: [loading, data], scope: container },
    );

    return (
        <section className="mission-values-section container" ref={container}>
            <div className="mission-section">
                <div className="mission-section__gradient" />
                <div className="mission-section__mask" style={maskStyle}>
                    <img src={background} alt="background" />
                </div>
                <div className="mission-section__title">
                    <div>{title}</div>
                </div>
                <div className="values-section">
                    {data?.map((card, index) => (
                        <div className="values-card-wrapper" key={index}>
                            <div className="angle-decor" />
                            <div className="values-card">
                                <div className="gradient-number light">
                                    <span>{index + 1}</span>
                                </div>
                                {card.title && <div className="values-card__title">{card.title}</div>}
                                {card.text && <div className="values-card__text">{card.text}</div>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default MissionAndValuesSection;
