import './styles.scss';

import { InstagramCard } from 'components/InstagramCard/InstagramCard';
import blackInstagram from 'images/icons/blackInstagram.svg';
import { useSelector } from 'react-redux';
import { selectHomepage } from 'store/slices/HomepageSlice';
import { Link } from 'react-router-dom';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useRef } from 'react';
import useMediaQuery from 'hooks/useMediaQuery';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import mask from 'images/insta-bg-mask.svg';
import bg from 'images/carles-rabada-hd2jIyRo7iY-unsplash (1) (1) (2).jpg';

gsap.registerPlugin(ScrollTrigger);

const NewInstagram = ({ loading }) => {
    const isTablet = useMediaQuery('max-width: 1023px');

    const maskStyle = { maskImage: `url('${mask}')`, WebkitMaskImage: `url('${mask}')` };

    const { instagram_photo_1, instagram_photo_2, instagram_photo_3, instagram_photo_4, instagram_title } =
        useSelector(selectHomepage);
    const { footer_instagram_link } = useSelector(selectTranslations);

    const yPercents = isTablet ? [-400, -150, -370, -105] : [-280, -120, -300, -150];

    const container = useRef();

    useGSAP(
        () => {
            gsap.from('.new-insta__content', {
                y: 10,
                opacity: 0,
                ease: 'power1.in',
                duration: 0.4,
                scrollTrigger: {
                    trigger: container.current,
                    start: 'top 70%',
                },
            });
            gsap.from('.new-insta__mask', {
                y: 10,
                opacity: 0,
                ease: 'power1.in',
                duration: 0.4,
                scrollTrigger: {
                    trigger: container.current,
                    start: 'top 50%',
                },
            });
        },
        { dependencies: [loading], scope: container },
    );

    useGSAP(
        () => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: container.current,
                    start: 'top 8px',
                    scrub: 1,
                    pin: true,
                },
            });
            tl.fromTo('.new-insta__mask', { maskSize: 60 }, { maskSize: 120 });
            tl.fromTo('.new-insta-anim-card__1', { yPercent: 0 }, { yPercent: yPercents[0] }, '-=.3');
            tl.fromTo('.new-insta-anim-card__3', { yPercent: 0 }, { yPercent: yPercents[2] }, '-=.35');
            tl.fromTo('.new-insta-anim-card__2', { yPercent: 0 }, { yPercent: yPercents[1] }, '-=.3');
            tl.fromTo('.new-insta-anim-card__4', { yPercent: 0 }, { yPercent: yPercents[3] }, '-=.35');
        },
        { dependencies: [loading], scope: container },
    );

    return (
        <section className="container" ref={container}>
            <div className="new-insta">
                <div className="new-insta__mask" style={maskStyle}>
                    <img src={bg} alt="background" />
                </div>
                <div className="new-insta__content">
                    <div className="h1">{instagram_title}</div>
                    <Link target="_blank" to={footer_instagram_link}>
                        <img src={blackInstagram} alt="instagram" />
                    </Link>
                </div>

                <div className="new-insta-anim-card new-insta-anim-card__1">
                    <InstagramCard image={instagram_photo_1} />
                </div>
                <div className="new-insta-anim-card new-insta-anim-card__2">
                    <InstagramCard image={instagram_photo_2} />
                </div>
                <div className="new-insta-anim-card new-insta-anim-card__3">
                    <InstagramCard image={instagram_photo_3} />
                </div>
                <div className="new-insta-anim-card new-insta-anim-card__4">
                    <InstagramCard image={instagram_photo_4} />
                </div>
            </div>
        </section>
    );
};

export default NewInstagram;
